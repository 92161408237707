.TagsInput{
  display: flex;
  flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
	min-height: 60px;
	width: 100%;
	padding: 0px;
	border: none;
	border-radius: 10px;
}

.FormControl{
  flex:1;
  display: block;
  width: 100%;
  border: none;
  min-height: 35px;
  font-size: 14px;
  border-radius: 5px;
  padding: 4px 10px;
}

.FormControl:focus{
  outline: transparent;
}

.TagsUL{
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.Tag{
  max-width: 100px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  padding: 0 8px;
  margin: 0px 5px 5px 0px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  background-color: #36C8D8;
}

.TagTitle{
  margin: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.TagClose{
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #36C8D8;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}
