.ModalTitle {
  color: #36c8d8;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.ModalHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.CloseButton {
  border: none;
  border-radius: 25px;
  position: absolute;
  right: 0;
  width: 35px;
  height: 35px;
  padding: 10px;
  margin-right: 15px;
  background-color: #36c8d8;
}

.CreateButton {
  width: 100%;
  padding: 15px 0px;
  background-color: #36c8d8;
  border: none;
  font-size: 25px;
  border-radius: 5px;
  margin: 0px !important;
}

.CloseButton:hover{
  background-color: #D94C20 !important;
}

.CreateButton:hover{
  background-color: #D94C20 !important;
}

.ModalForm {
  width: 100%;
}

.ModalInput {
  width: 100% !important;
  border: none;
  border-radius: 5px !important;
}

.ErrorText {
  color: red;
  font-weight: bold;
  margin-top: 5px;
}

.ImagesInput {
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 8px 10px;
  outline: none;
}

.ImagesPopover {
  z-index: 1;
  color: rgb(182, 182, 182);
  width: 30px;
  margin-left: -85px;
}
